import { DefaultValues } from 'react-hook-form';
import { DOCUMENTS_FINAL_TERMS_STEP } from 'pages/Instruments/components/Form/EtpDetails/EtpDetails.steps';
import {
  FinalTermsProps,
  DocumentsRegion,
} from 'pages/Instruments/components/Form/EtpDetails/EtpDetails.types';
import { instrumentLegalDocumentsSchemaUrl } from 'components/Form/formSchemas';
import Form from 'components/Form/Form';
import { useSaveETPHook } from 'pages/Instruments/useSaveETPHook';
import { ETPStepSaveProgress } from 'pages/Instruments/components/SaveProgress';
import { useInstrumentStepFormData } from 'store/instruments/selectors';
import FormGridItem from 'components/Form/components/FormGridItem';
import FormGridContainer from 'components/Form/components/FormGridContainer';
import MultiSelect, { MultiSelectProps } from 'components/MultiSelect/MultiSelect';
import { Checkbox } from 'components/BooleanInputs';
import { Box, styled as MuiStyled, Typography } from '@mui/material';
import Footer from 'pages/Instruments/components/Footer';

interface FinalTermsStepProps {
  onSubmit: () => void;
  goBack: () => void;
}

interface FinalTermsSelectorProps {
  region: DocumentsRegion;
}

const StrongContainer = MuiStyled(Box)(({ theme }) => ({
  background: theme.customPalette.static.background.default.strong,
  borderRadius: theme.shape.borderRadius,
  marginTop: theme.spacing(2),
}));

const InfoText = MuiStyled(Typography)(({ theme }) => ({
  fontSize: theme.typography.body2.fontSize,
  color: theme.customPalette.static.text.default.standard,
  padding: theme.spacing(2),
}));

function DocumentsSelector({ region }: FinalTermsSelectorProps) {
  return (
    <FormGridItem key={region} md={3}>
      <StrongContainer>
        <FormGridContainer margin={0} padding={0}>
          <FormGridItem md={12} style={{ fontWeight: 'bold' }}>
            {region.toUpperCase()}
          </FormGridItem>
          <FormGridItem md={6}>
            <Checkbox name={`${region}FtEnabled`} label="Send FT" />
          </FormGridItem>
          <FormGridItem md={6}>
            <Checkbox name={`${region}IssEnabled`} label="Send ISS" />
          </FormGridItem>
        </FormGridContainer>
      </StrongContainer>
    </FormGridItem>
  );
}

const regionsOptions: MultiSelectProps<DocumentsRegion>['options'] = [
  { label: 'EU', value: 'eu' },
  { label: 'UK', value: 'uk' },
];

function FinalTermsStep({ onSubmit, goBack }: FinalTermsStepProps) {
  const { saveInstrumentDraft, saveInstrumentToStore } = useSaveETPHook();
  const formData = useInstrumentStepFormData(DOCUMENTS_FINAL_TERMS_STEP) as FinalTermsProps;

  const saveValues = (data: DefaultValues<FinalTermsProps>) => {
    let saveInstrument = saveInstrumentDraft;
    if (
      (data.regions?.includes('eu') && !(data.euFtEnabled || data.euIssEnabled)) ||
      (data.regions?.includes('uk') && !(data.ukFtEnabled || data.ukIssEnabled))
    ) {
      saveInstrument = saveInstrumentToStore;
    }

    saveInstrument({
      [DOCUMENTS_FINAL_TERMS_STEP]: {
        ...data,
        euFtEnabled: data.regions?.includes('eu') && data.euFtEnabled,
        euIssEnabled: data.regions?.includes('eu') && data.euIssEnabled,
        ukFtEnabled: data.regions?.includes('uk') && data.ukFtEnabled,
        ukIssEnabled: data.regions?.includes('uk') && data.ukIssEnabled,
      } as FinalTermsProps,
    });
  };

  return (
    <Form<FinalTermsProps>
      initialValues={formData}
      onBlur={saveValues}
      onSubmit={onSubmit}
      schemaUrl={instrumentLegalDocumentsSchemaUrl}
    >
      <FormGridContainer>
        <ETPStepSaveProgress />
        <FormGridItem md={12}>
          <FormGridItem md={6}>
            <MultiSelect
              name="regions"
              label="Regions *"
              size="large"
              options={regionsOptions}
              value={formData.regions}
            />
          </FormGridItem>
        </FormGridItem>
        {formData.regions?.map((region) => DocumentsSelector({ region }))}
        <FormGridItem md={12}>
          <StrongContainer>
            <InfoText>
              These configurations will determine which Final Terms files are sent to the Final
              Terms Distribution Lists
            </InfoText>
          </StrongContainer>
        </FormGridItem>
      </FormGridContainer>
      <Footer goBack={goBack} />
    </Form>
  );
}

export default FinalTermsStep;
